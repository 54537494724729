import axios from '@axios'
import { format, parseISO } from 'date-fns'

function transformEvent(event) {
  /* eslint-disable camelcase */
  const {
    workplaceId: workplace_id,
    workplaceDutyId: workplace_duty_id,
    workplaceDuty: workplace_duty,
    physicianId: physician_id,
    personType: person_type,
    physicianCompanyId: physician_company_id,
    start,
    end,
    amountPaid,
    confirmed,
    released,
  } = event
  /* eslint-enable camelcase */
  return {
    workplace_id,
    workplace_duty_id,
    workplace_duty,
    physician_id,
    person_type,
    physician_company_id,
    start_date: format(start, 'yyyy-MM-dd HH:mm'),
    end_date: format(end, 'yyyy-MM-dd HH:mm'),
    amount_paid: (+amountPaid) * 100,
    confirmed,
    released,
  }
}

export default {
  namespaced: true,
  state: {
    currentWorkplaceId: null,
    calendarOptions: [],
    dutyTypesOptions: [],
    selectedCalendars: [],
    selectedDutyTypes: [],
    currentPhysicians: [],
  },
  getters: {},
  mutations: {
    SET_WORKPLACE_ID(state, id) {
      state.currentWorkplaceId = id
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_SELECTED_DUTY_TYPES(state, val) {
      state.selectedDutyTypes = val
    },
    SET_PHYSICIANS(state, val) {
      const colors = ['danger', 'primary', 'warning', 'success', 'info']
      state.calendarOptions = val.map((physician, i) => ({
        id: physician.id,
        color: colors[i % colors.length],
        label: physician.name.split(' ').slice(0, 3).join(' '),
        fullLabel: physician.name,
        physician,
      }))
    },
    SET_DUTY_TYPES(state, val) {
      const colors = ['danger', 'primary', 'warning', 'success', 'info']
      // state.selectedDutyTypes = null
      state.dutyTypesOptions = val.map((workplace, i) => ({
        id: workplace.id,
        color: colors[i % colors.length],
        label: workplace.type,
        fullLabel: workplace.type,
      }))
    },
    SET_PHYSICIANS_IN_SCHEDULE(state, val) {
      state.currentPhysicians = val.map(physician => ({
        id: physician.id,
        name: physician.name,
      }))
    },
  },
  actions: {
    dutyTypes(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/duty-type/active', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setWorkplaceId({ commit }, id) {
      commit('SET_WORKPLACE_ID', id)
    },
    fetchPhysiciansInSchedule(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/in-schedule', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDutyTypeInSchedule(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/duty-type/in-schedule', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDutyTypeByWorkplace(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/duty-type/by-workplace', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysicians() {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/all-by-institution')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysiciansPj() {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/pj-by-institution')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysicianDuties(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/physician-duty/all', {
            workplace_id: params.workplaceId,
            start_date: params.start && format(parseISO(params.start), 'yyyy-MM-dd HH:mm:ss'),
            end_date: params.end && format(parseISO(params.end), 'yyyy-MM-dd HH:mm:ss'),
            physicians: params.physicians,
            duty_types: params.dutyTypes || [],
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPhysicianDuty(ctx, event) {
      return new Promise((resolve, reject) => {
        axios
          .post('/physician-duty', transformEvent(event))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePhysicianDuty(ctx, event) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/physician-duty/${event.id}`, transformEvent(event))
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removePhysicianDuty(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/physician-duty/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
