// Full Calendar Plugins
import { isEqual } from 'lodash'
import rrulePlugin from '@fullcalendar/rrule'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import ptBrLocale from '@fullcalendar/core/locales/pt-br'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import store from '@/store'
import { intervalToDuration } from 'date-fns'

export default function userCalendar() {
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)
  const calendarFilters = {
    startDate: null,
    endDate: null,
  }

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    Business: 'primary',
    Holiday: 'success',
    Personal: 'danger',
    Family: 'warning',
    ETC: 'info',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    id: '',
    start: '',
    end: '',
    extendedProps: {
      eventData: {},
      physicianId: null,
      physicianCompanyId: null,
      workplaceDutyId: null,
      workplaceDuty: null,
      amountPaid: null,
      timeInterval: null,
      integrationCode: null,
      confirmed: false,
      released: false,
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // const parseEventColor = filled => (filled ? 'rgba(255, 144, 14, 0.12)' : 'rgba(87, 87, 86, 0.2)')

  const getColor = eventData => {
    if (eventData.confirmed) return 'rgba(33,197,98,0.55)'
    if (eventData.released) return 'rgba(171,86,209,0.73)'
    return 'rgba(42,42,42,0.25)'
  }

  function convertMinutesToHoursMinutes(minutes) {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    return `${`${hours}`.padStart(2, '0')}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`
  }

  const parseEvent = eventData => {
    const isFilled = eventData.physician
    const duty = `(Plantão ${eventData.duty})`
    const title = isFilled ? `${eventData.physician.name} ${duty}` : duty
    const start = new Date(`${eventData.start_date}-03:00`)
    const end = new Date(`${eventData.end_date}-03:00`)
    const inter = intervalToDuration({ start, end })
    const timeInterval = convertMinutesToHoursMinutes((inter.days * 24 * 60) + (inter.hours * 60) + inter.minutes)
    return {
      id: eventData.id,
      title,
      color: getColor(eventData),
      start,
      end,
      extendedProps: {
        eventData: { ...eventData },
        physicianId: eventData.physician ? eventData.physician.id : '',
        physicianCompanyId: eventData.physician_company ? eventData.physician_company.id : '',
        workplaceDutyId: eventData.workplace_duty_id,
        workplaceDuty: eventData.workplace_duty,
        integrationCode: eventData.integration_code,
        amountPaid: eventData.amount_paid / 100,
        timeInterval,
        confirmed: eventData.confirmed,
        released: eventData.released,
      },
    }
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updatePhysicianDutyInCalendar
  // ------------------------------------------------
  const updatePhysicianDutyInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Plantão Atualizado',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removePhysicianDutyInCalendar
  // ------------------------------------------------
  const removePhysicianDutyInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Plantão removido',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { eventData, physicianCompanyId, physicianId, workplaceDutyId, workplaceDuty, amountPaid, integrationCode, confirmed, released },
      allDay,
    } = eventApi
    const inter = intervalToDuration({ start, end })
    const timeInterval = convertMinutesToHoursMinutes((inter.days * 24 * 60) + (inter.hours * 60) + inter.minutes)
    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        eventData,
        physicianId,
        physicianCompanyId,
        workplaceDutyId,
        workplaceDuty,
        integrationCode,
        amountPaid,
        timeInterval,
        confirmed,
        released,
      },
      allDay,
    }
  }

  const parsePhysicianDuty = eventData => {
    const { currentWorkplaceId: workplaceId } = store.state['physician-duty']
    const {
      id,
      start,
      end,
      extendedProps: {
        personType, physicianCompanyId, physicianId, workplaceDutyId, workplaceDuty, amountPaid, integrationCode, confirmed, released,
      },
    } = eventData
    return {
      id,
      start,
      end,
      personType,
      physicianCompanyId,
      physicianId,
      workplaceDutyId,
      workplaceDuty,
      workplaceId,
      integrationCode,
      amountPaid,
      confirmed,
      released,
    }
  }
  // ------------------------------------------------
  // addPhysicianDuty
  // ------------------------------------------------
  const addPhysicianDuty = eventData => {
    store.dispatch('physician-duty/addPhysicianDuty', parsePhysicianDuty(eventData)).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    }).catch(err => {
      toast({
        component: ToastificationContent,
        props: {
          title: err.response.data.message || 'Erro ao atualizar',
          icon: 'CloseIcon',
          variant: 'danger',
        },
      })
    })
  }

  // ------------------------------------------------
  // updatePhysicianDuty
  // ------------------------------------------------
  const updatePhysicianDuty = eventData => {
    store.dispatch('physician-duty/updatePhysicianDuty', parsePhysicianDuty(eventData)).then(response => {
      const updatedEvent = parseEvent(response.data.data)

      const propsToUpdate = ['id', 'title', 'color']
      const extendedPropsToUpdate = ['eventData', 'personType', 'timeInterval', 'physicianCompanyId', 'physicianId', 'workplaceDutyId', 'workplaceDuty', 'amountPaid', 'integrationCode', 'confirmed', 'released']

      updatePhysicianDutyInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
      .catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message || 'Erro ao atualizar',
            icon: 'CloseIcon',
            variant: 'danger',
          },
        })
      })
  }

  // ------------------------------------------------
  // removePhysicianDuty
  // ------------------------------------------------
  const removePhysicianDuty = () => {
    const eventId = event.value.id
    store.dispatch('physician-duty/removePhysicianDuty', { id: eventId }).then(() => {
      removePhysicianDutyInCalendar(eventId)
    })
  }

  let lastDutyTypeParams = {}
  const fetchDutyTypes = () => {
    const { startDate, endDate } = calendarFilters
    const workplaceId = store.state['physician-duty'].currentWorkplaceId
    const params = {
      workplace_id: workplaceId,
      start_date: startDate,
      end_date: endDate,
    }
    if (isEqual(lastDutyTypeParams, params)) {
      lastDutyTypeParams = params
      return
    }
    lastDutyTypeParams = params
    store.commit('physician-duty/SET_SELECTED_DUTY_TYPES', null)

    store
      .dispatch('physician-duty/fetchDutyTypeByWorkplace', { workplace_id: workplaceId })
      // .dispatch('physician-duty/fetchDutyTypeInSchedule', params)
      .then(response => {
        store.commit('physician-duty/SET_DUTY_TYPES', response.data.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar médicos',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state['physician-duty'].selectedCalendars)
  const selectedDutyTypes = computed(() => store.state['physician-duty'].selectedDutyTypes)

  watch(selectedCalendars, () => {
    refetchEvents()
  })

  watch(selectedDutyTypes, () => {
    if (selectedDutyTypes !== null) {
      refetchEvents()
    }
  })

  const fetchPhysicians = () => {
    store
      .dispatch('physician-duty/fetchPhysicians')
      .then(response => {
        store.commit('physician-duty/SET_PHYSICIANS', response.data.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar médicos',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    calendarFilters.startDate = info.startStr
    calendarFilters.endDate = info.endStr

    // Fetch Events from API endpoint
    const workplaceId = store.state['physician-duty'].currentWorkplaceId
    store
      .dispatch('physician-duty/fetchPhysicianDuties', {
        workplaceId,
        start: info.startStr,
        end: info.endStr,
        physicians: selectedCalendars.value,
        dutyTypes: selectedDutyTypes && selectedDutyTypes.value,
      })
      .then(response => {
        const events = response.data.data.map(parseEvent)
        fetchDutyTypes()
        successCallback(events)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar eventos da agenda',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    locale: ptBrLocale,
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, rrulePlugin],
    initialView: 'dayGridMonth',
    eventDisplay: 'block',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    events: fetchEvents,

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: false,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 3,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
    },

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    eventClick({ event: clickedEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)

      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updatePhysicianDuty(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updatePhysicianDuty(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addPhysicianDuty,
    updatePhysicianDuty,
    removePhysicianDuty,
    refetchEvents,
    fetchEvents,
    fetchPhysicians,
    fetchDutyTypes,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
